import React from "react"
import PrimaryLayout from "../layouts/PrimaryLayout"
export default () => (
  <PrimaryLayout column="col-xs-6">
    <h1>About Us</h1>
    <p>
      With offices in the Melbourne CBD, on the north shore and Dee Why on the
      northern beaches, Reid & Montoya delivers specialised law
      services throughout the Melbourne metropolitan area. We also cater for
      interstate and overseas clients, and our Melbourne CBD office is located
      just a short walk from the Law Courts.
    </p>
    <p>
      At Reid & Montoya, we provide a professional and specialised
      approach to personal injury Law. We have a team of personal injury Lawyers, with many of
      them being Law Society Accredited personal injury Law Specialists. Our combined
      experience of more than 100 years together with a highly skilled support
      staff and the latest technology enables us to provide a first rate,
      personal service to our clients. We are committed to achieving the best
      possible outcomes in all matters and dedicated to meeting the needs of our
      clients.
    </p>
    <p>
      At Reid & Montoya, we understand that separation can be a
      difficult and stressful time for partners and their children. Our experts
      strive to provide the advice, guidance and solutions necessary to help you
      resolve your legal issues as quickly as possible.
    </p>
    <p>
      We pride ourselves on our ability to quickly recognise the legal issues
      which present themselves when your marriage or personal relationship fails
      and we provide professional and practical advice, ensuring you are well
      informed both as to your legal entitlements and the processes which you
      can call upon to resolve your matter. We understand that each relationship
      is different and that your personal situation may change at some stage
      throughout your matter. Our high degree of expertise, accompanied with our
      sensitive and practical approach, means that you can be assured that we
      will provide the support, advice and assistance that you need to obtain
      the best possible outcome in your own unique situation.
    </p>
  </PrimaryLayout>
)
